<template>
  <div>
    <div class="align-items-center border-grey qu-fc-color curser-pointer m-1" v-b-modal.previewQuestions
      @click="getQuestions" :class="{ 'disabled': questions.length <= 0 }"
      @click.prevent="questions.length > 0 && getQuestions">
      <span>Preview Questions</span>
    </div>

    <b-modal id="previewQuestions" ref="previewQuestions" hide-footer size="lg" centered title="Preview Questions"
      @shown="playAudio" @hidden="resetModal">
      <div class="modal_content">
        <div v-if="questions.length > 0" style=" position: relative;">
          <div class="question-container" style="position: relative;  justify-content: center; align-items: center;">
            <h3 class="question-title" @click="
              textToSpeak(questions[currentQuestionIndex].titleTts)
              ">{{ questions[currentQuestionIndex].title }}
            </h3>
            <div class="question-number">
              {{ currentQuestionIndex + 1 }} / {{ questions.length }}
            </div>
          </div>

          <div v-if="isCodeInArray(generateShortcode(questions), 'noDescriptionAndFourOrSixTextOrImageOptions')"
            class="option-list d-flex flex-wrap p-3"
            style="justify-content: center !important; align-items: center !important;padding-bottom: 20px !important;">
            <div style="width: 70%;">
              <div style="width: 100%;">
                <div v-for="(option, index) in filteredOptions" :key="index" class="checkbox-groupx">
                  <template v-if="option.title && (option.title != null || option.title != '')">
                    <template
                      v-if="option.title && (option.title.includes(',') || containsEnglish(option.title)) && !option.image">
                      <label class="circle-checkbox-container">
                        <div class="checkbox-col" :style="{
                          background: 'linear-gradient(90deg, ' + colors[index] + ' 100%, white 100%)'
                        }">
                          <div>
                            <input type="checkbox" :checked="isSelected(index)"
                              @change="selectCheckBox(index, option)" />
                          </div>
                        </div>
                        <div class="checkbox-title">{{ option.title }}</div>
                      </label>
                    </template>

                  </template>
                </div>
              </div>
            </div>
            <div
              :style="{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '5px', overflow: 'hidden', width: '100%', flexWrap: 'wrap' }">

              <template v-for="(option, index) in filteredOptions"
                v-if="isArithmeticExpression(option.title) || option.image">

                <div :style="{
                  borderRadius: '5px',
                  overflow: 'hidden',
                  backgroundColor: 'white',
                  width: totalOptions < 5 ? '130px' : '160px',
                  height: totalOptions < 5 ? '130px' : '160px',
                  margin: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: isCurrentOptionSelected(option) ? '2px solid #0D5CAB' : '2px solid white',
                  transition: 'transform 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55), border 0.3s ease',
                  transform: isCurrentOptionSelected(option) ? 'scale(1.1)' : 'scale(1)',
                  cursor: 'pointer',
                }" @click="handleMultiOptionsSelection(option)">
                  <template v-if="option.image">
                    <img :src="option.image" :alt="'Option Image ' + index" :style="{ width: '70%', height: 'auto' }" />
                  </template>
                  <template v-else="option.title">
                    <h1 style="text-align: center; color: black; font-size: 40px; font-weight: bold;">
                      {{ option.title }}
                    </h1>
                  </template>
                </div>
              </template>
            </div>
          </div>


          <!-- 2 images in option-->
          <div v-else-if="isCodeInArray(generateShortcode(questions), 'noDescriptionAndTwoImageOptions')"
            class="option-list d-flex flex-wrap p-3">
            <div
              :style="{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', gap: '50px' }">
              <div v-for="(option, optionIndex) in filteredOptions" :key="optionIndex">
                <div v-if="option.image" :style="{
                  borderRadius: '5px',
                  backgroundColor: 'white',
                  width: '250px',
                  height: '250px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  border: selectedOption && selectedOption.image === option.image ? '2px solid #0D5CAB' : '2px solid white',
                  transition: 'transform 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55), border 0.3s ease',
                  transform: selectedOption && selectedOption.image === option.image ? 'scale(1.1)' : 'scale(1)',
                }" @click="
                  handleSelection(option)
                  ">
                  <img :src="option.image" :alt="'Option Image ' + optionIndex" :style="{
                    objectFit: 'cover',
                    width: '60%',
                    height: 'auto',
                  }" />
                </div>
              </div>
            </div>
          </div>

          <!-- oneDescriptionTextAndFourOrThreeOrTwoImagesInOption -->

          <div
            v-else-if="isCodeInArray(generateShortcode(questions), 'oneDescriptionTextAndFourOrThreeOrTwoImagesInOption')"
            class="option-list d-flex flex-wrap p-3" style="padding-top: 1rem !important">
            <div v-for="(description, optionIndex) in questions[currentQuestionIndex].descriptions" :key="optionIndex">
              <div v-if="description.title" :style="{
                width: '650px', minHeight: '200px', textAlign: 'center', margin: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '5px', overflow: 'hidden',
                paddingTop: '20px', paddingBottom: '20px', border: '2px solid #0D5CAB'
              }">
                <h1 style="max-width: 90%; text-align: 'center'; color: white; font-size: 33px;font-weight: 600;">
                  {{ description.title }}
                </h1>
              </div>
              <div :style="{
                display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', flexWrap: 'wrap',
                gap: '20px', marginTop: '10%'
              }">
                <div v-for="(option, index) in filteredOptions" :key="index" :style="{
                  display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '5px', overflow: 'hidden', backgroundColor: 'white', width: getImageWith(totalOptions), height: getImageWith(totalOptions),
                  border: selectedOption && selectedOption.image === option.image ? '2px solid #0D5CAB' : '2px solid white',
                  transition: 'transform 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55), border 0.3s ease',
                  transform: selectedOption && selectedOption.image === option.image ? 'scale(1.1)' : 'scale(1)',
                }" v-if="option.image" @click="
                  handleSelection(option)">
                  <img :src="option.image" :alt="'Option Image ' + index" :style="{ width: '100%', height: 'auto', }" />
                </div>
              </div>
            </div>
          </div>

          <div
            v-else-if="isCodeInArray(generateShortcode(questions), 'oneDescriptionImageAndFourOrThreeImagesInOption')"
            class="option-list d-flex flex-wrap p-3" style="padding-top: 1rem !important">
            <div v-for="(description, index) in questions[currentQuestionIndex].descriptions" :key="index">
              <div :style="{
                width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',
              }">
                <div v-if="description.image[0] && description.image[0].url" :style="{
                  width: '400px', height: '260px', textAlign: 'center', margin: '10px', borderRadius: '5px', overflow: 'hidden', backgroundColor: '#ffffffa8',
                }">
                  <img v-if="description.image[0] && description.image[0].url" :src="description.image[0].url"
                    :alt="'Plant Diagram ' + (index + 1)" style="width: 50%; height: auto;">
                </div>
              </div>
              <div :style="{
                display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', flexWrap: 'wrap',
                gap: '20px', marginTop: '10%'
              }">
                <div v-for="(option, index) in filteredOptions" :key="index" :style="{
                  display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '5px', overflow: 'hidden', backgroundColor: 'white', width: getImageWith(totalOptions), height: getImageWith(totalOptions), border: selectedOption && selectedOption.image === option.image ? '2px solid #0D5CAB' : '2px solid white',
                  transition: 'transform 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55), border 0.3s ease',
                  transform: selectedOption && selectedOption.image === option.image ? 'scale(1.1)' : 'scale(1)',
                }" v-if="option.image" @click="
                  handleSelection(option)">
                  <img :src="option.image" :alt="'Option Image ' + index" :style="{ width: '40%', height: 'auto', }" />
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="isCodeInArray(generateShortcode(questions), 'oneDescriptionImageAndFourTextOptions')"
            class=" option-list d-flex flex-wrap p-3">
            <div
              style="display: flex; flex-direction: column; align-items: center; justify-content: center; width: 100%;">
              <div v-for="(description, index) in questions[currentQuestionIndex].descriptions" :key="'image-' + index"
                :style="{
                  backgroundColor: 'white', width: description.title == null ? '300px' : '650px', minHeight: '200px', textAlign: 'center', margin: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '5px', overflow: 'hidden',
                  paddingTop: '20px', paddingBottom: '20px',
                }">
                <img v-if="description.image[0] && description.image[0].url" :src="description.image[0].url"
                  :alt="'Plant Diagram ' + (index + 1)" style="width: 170px; height: auto;">
                <h1 v-else-if="description.title"
                  style="max-width: 90%; text-align: 'center'; color: black; font-size: 40px; font-weight: bold;">
                  {{ description.title }}
                </h1>
              </div>

              <div
                style="display: flex; flex-wrap: wrap; margin-top: 5%; justify-content: space-between; width: 100%; gap:10px">
                <div v-for="(option, index) in filteredOptions" :key="index"
                  v-if="option.title != null && option.title.length > 0" :style="{
                    backgroundImage: 'url(' + getOptionBackground(index) + ')',
                    backgroundSize: 'cover',
                    flex: '1 1 1',
                    marginTop: '10px',
                    padding: '20px 30px',
                    color: 'white',
                    borderRadius: '10px',
                    cursor: 'pointer',
                    textAlign: 'center',
                    fontWeight: '600',
                    fontSize: '25px',
                    width: getWidth(totalOptions, option.title.length),
                    transition: 'transform 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55), border 0.3s ease',
                    transform: selectedOption && selectedOption.title === option.title ? 'scale(1.1)' : 'scale(1)',
                  }" @click="
                    handleSelection(option)">
                  {{ option.title }}
                </div>
              </div>
            </div>
          </div>

          <!-- All format  -->

          <div v-else
            style="display: flex; flex-direction: column; align-items: center; justify-content: center; width: 100%;">
            <div v-for="(description, index) in questions[currentQuestionIndex].descriptions" :key="'image-' + index">
              <template :style="{
                backgroundColor: 'white', width: description.title == null ? '300px' : '650px', minHeight: '200px', textAlign: 'center', margin: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '5px', overflow: 'hidden',
                paddingTop: '20px', paddingBottom: '20px',
              }" v-if="description.image || description.title">
                <img v-if="description.image[0] && description.image[0].url" :src="description.image[0].url"
                  :alt="'Plant Diagram ' + (index + 1)" style="width: 170px; height: auto;">
                <h1 v-else-if="description.title"
                  style="max-width: 90%; text-align: 'center'; color: black; font-size: 40px; font-weight: bold;">
                  {{ description.title }}
                </h1>
              </template>
            </div>

            <div
              style="display: flex; flex-wrap: wrap; margin-top: 5%; justify-content: space-between; width: 100%; gap:10px">
              <div v-for="(option, index) in filteredOptions" :key="index">
                <template v-if="option.title != null && option.title.length > 0" :style="{
              display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '5px', overflow: 'hidden', backgroundColor: 'white', width: getImageWith(totalOptions), height: getImageWith(totalOptions),
              border: selectedOption && selectedOption.image === option.image ? '2px solid #0D5CAB' : '2px solid white',
              transition: 'transform 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55), border 0.3s ease',
              transform: selectedOption && selectedOption.image === option.image ? 'scale(1.1)' : 'scale(1)',
            }" @click="
              handleSelection(option)">
                  <img :src="option.image" :alt="'Option Image ' + index" :style="{ width: '100%', height: 'auto', }" />
                </template>
                <template v-else :style="{
                  backgroundImage: 'url(' + getOptionBackground(index) + ')',
                  backgroundSize: 'cover',
                  flex: '1 1 1',
                  marginTop: '10px',
                  padding: '20px 30px',
                  color: 'white',
                  borderRadius: '10px',
                  cursor: 'pointer',
                  textAlign: 'center',
                  fontWeight: '600',
                  fontSize: '25px',
                  width: getWidth(totalOptions, option.title.length),
                  transition: 'transform 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55), border 0.3s ease',
                  transform: selectedOption && selectedOption.title === option.title ? 'scale(1.1)' : 'scale(1)',
                }" @click="
                  handleSelection(option)">
                  {{ option.title }}
                </template>

              </div>
            </div>

          </div>

          <div v-if="correctOption" style="position: absolute; top: 0%;">
            <lottie :options="confettiOptions"></lottie>
          </div>

          <div style="display: flex; justify-content: center; align-items: center; margin-bottom: 30px;">
            <img :src="getImage" :alt="'check or unchecked image'"
              :style="{ width: '7%', height: 'auto', cursor: 'pointer' }"
              @click="handleOptionSelection(selectedOption, selectedOptionsArray)" />

          </div>


        </div>
        <div class="modal-footer">
          <b-button variant="link" class="prev-icon" @click="prevQuestion" :disabled="currentQuestionIndex === 0">
            <img src="../../../../public/previous.png" alt="Previous" />
          </b-button>

          <b-button variant="link" class="next-icon" @click="nextQuestion"
            :disabled="currentQuestionIndex === questions.length - 1">
            <img src="../../../../public/next.png" alt="Next" />
          </b-button>
        </div>

      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BAlert,
  BSpinner,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import blueButton from '../../../../public/blue-btn.png'
import purpleButton from '../../../../public/purple-btn.png'
import yellowButton from '../../../../public/yellow-btn.png'
import grayCheck from '../../../../public/grey_check.png'
import blueCheck from '../../../../public/blue_check.png'
import Confetti from '../../../../public/Confetti.json'
import amazoniteButton from '../../../../public/amazonite-btn.png'
import { getDescriptionCode, getOptions } from "@/views/pages/helpers";
import { shortcodes } from "@/views/app/data/data";
import Lottie from 'vue-lottie';

export default {
  components: {
    BButton,
    BAlert,
    BSpinner,
    ToastificationContent,
    Lottie,
    yellowButton,
    blueButton,
    purpleButton,
    amazoniteButton,

  },
  data() {
    return {
      questionText: "",
      alertMessageToast: "",
      currentQuestionIndex: 0,
      colors: ['#FDD835', '#7E57C2', '#42A5F5', '#66BB6A'],
      selectedOptionsArray: [],
      utterance: null,
      blueCheck,
      grayCheck,
      Confetti,
      confettiOptions: {
        loop: false,
        autoplay: true,
        animationData: Confetti
      },
      correctOption: false,
      selectedOption: null,
      selectedCheckBox: null,
    };

  },
  computed: {
    filteredOptions() {
      return this.filterOptions(this.questions[this.currentQuestionIndex].options);
    },
    totalOptions() {
      return this.filteredOptions.length;
    },


    getImage() {
      if (this.selectedOption != null) {
        return this.blueCheck;
      }
      if (this.selectedOptionsArray) {
        if (this.selectedOptionsArray.length > 0) {
          return this.blueCheck;
        }
        else {
          return this.grayCheck;
        }
      }
      else {
        return this.grayCheck;
      }
    }
  },


  methods: {
    isSelected(index) {
      return this.selectedCheckBox === index;
    },

    getCorrectOptionsCount() {
      return this.filteredOptions.filter(option => option.correct).length;
    },
    isCurrentOptionSelected(option) {
      if (this.selectedOptionsArray) {
        if (option.title) {
          return this.selectedOptionsArray.some(selected => selected.title === option.title);
        }

        if (option.image) {
          return this.selectedOptionsArray.some(selected => selected.image === option.image);
        }
      }

      return false;
    },

    selectCheckBox(index, option) {
      if (this.selectedCheckBox === index) {
        this.selectedCheckBox = null;
      } else {
        this.selectedCheckBox = index;
      }
      this.handleSelection(option);

    },
    filterOptions(options) {
      const validOptions = options.filter(option =>
        (option.title && option.title.trim() !== '') ||
        (option.image && option.image.trim() !== '')
      );
      return validOptions;
    },

    containsEnglish(text) {
      const englishPattern = /[a-zA-Z]/;
      return englishPattern.test(text);
    },

    handleOptionSelection(selected, selectedArray) {

      if (selected) {
        if (selected.correct) {
          this.textToSpeak('You are awesome');
          this.selectedOption = null;
          this.correctOption = true;
          setTimeout(() => {
            this.correctOption = false;
            this.nextQuestion();
          }, 1500);
        } else {
          this.textToSpeak('please try again');
          this.selectedOption = null;
        }
      }

      else {
        const allCorrect = selectedArray.every(item => item.correct);
        const correctCount = selectedArray.filter(item => item.correct).length;
        if (allCorrect && correctCount === this.getCorrectOptionsCount()) {
          this.textToSpeak('You are awesome');
          this.selectedOption = null;
          this.correctOption = true;
          setTimeout(() => {
            this.correctOption = false;
            this.nextQuestion();
          }, 1500);
        } else {
          this.textToSpeak('please try again');
          this.selectedOptionsArray = null;
        }
      }
    },


    playAudio() {
      this.textToSpeak(this.questions[this.currentQuestionIndex].titleTts);

    },

    handleSelection(option) {

      this.selectedOption = option;
      this.textToSpeak(option.tts);
    },

    handleMultiOptionsSelection(option) {
      this.textToSpeak(option.tts);

      if (!this.selectedOptionsArray) {
        this.selectedOptionsArray = [];
      }

      let index;
      if (option.image) {
        index = this.selectedOptionsArray.findIndex(selected => selected.image === option.image);
      }
      else if (option.title) {
        index = this.selectedOptionsArray.findIndex(selected => selected.title === option.title);
      }

      if (index === -1) {
        this.selectedOptionsArray.push(option);
      } else {
        this.selectedOptionsArray.splice(index, 1);
      }

    },

    textToSpeak(text) {
      let speakText = text === null ? "" : text;
      if ("speechSynthesis" in window) {
        if (this.utterance) {
          window.speechSynthesis.cancel();
        }
        this.utterance = new SpeechSynthesisUtterance(speakText);
        window.speechSynthesis.speak(this.utterance);
      } else {
        alert("Sorry, your browser does not support text-to-speech.");
      }
    },

    isArithmeticExpression(title) {
      const regex = /^\d+$|^\d+\s*[-+*/]\s*\d+$/;

      return regex.test(title);
    },
    updateCheckBox(index) {

    },

    getWidth(totalOptions, length) {
      if (totalOptions == 2) {
        return '45%';
      }
      else if ((totalOptions == 3 || totalOptions == 4) && length < 10) {
        return '23%';
      }
      else { return '45%'; }
    },

    getImageWith(totalOptions) {

      if (totalOptions == 2) {
        return '250px'
      }
      else if (totalOptions == 3) {
        return '200px'
      }
      else if (totalOptions == 4) {
        return '150px'
      }


    },

    isCodeInArray(code, name) {
      const foundItem = shortcodes.find(item => item.name === name);
      if (foundItem) {
        const shortcodeArray = foundItem.shortcode || foundItem.shotcodes;
        if (shortcodeArray.includes(code)) {
          return true;
        }
      }
      return false;
    },

    getQuestions() {
    },
    generateShortcode(questions) {
      let currentQuestion = questions[this.currentQuestionIndex];
      let desc_code = getDescriptionCode(currentQuestion.descriptions);
      console.log('=currentQuestion=',currentQuestion);
      let option_code = getOptions(currentQuestion)
      return desc_code + option_code;
    },

    getOptionBackground(optionIndex) {
      const images = [yellowButton, blueButton, amazoniteButton, purpleButton,];
      return images[optionIndex];
    },
    showToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: this.alertMessageToast,
          variant,
        },
      });
    },
    prevQuestion() {
      if (this.currentQuestionIndex > 0) {
        this.currentQuestionIndex--;
        this.playAudio();
        this.selectedOption = null;
      }
      this.selectedOption = null;
      this.selectedCheckBox = null;
      this.selectedOptionsArray = null;
    },
    nextQuestion() {
      if (this.currentQuestionIndex < this.questions.length - 1) {
        this.currentQuestionIndex++;
        this.playAudio();
        this.selectedOption = null;
        this.selectedCheckBox = null;
        this.selectedOptionsArray = null;
      }
      else {
        if (this.$refs.previewQuestions) {
          this.$refs.previewQuestions.hide();
        }
      }
    },
    resetModal() {
      this.currentQuestionIndex = 0;
      this.selectedOption = null;
    },

  },
  props: ["questions"],
};
</script>

<style>
#add-questions-modal .card-body {
  padding: 0px;
}

.curser-pointer {
  cursor: pointer;
}

ol {
  list-style-type: lower-alpha;
}

#previewQuestions .modal-content {
  background-image: url('../../../../public/Scene_Default.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 8px;
  color: white;
}

.question-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.question-number {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: white;
  border-radius: 10px;
  padding: 5px 10px;
  font-size: 12px;
  color: #333;
}


.prev-icon img {
  width: 45px;
}

#previewQuestions .modal-footer {
  padding: unset;
}

#previewQuestions .modal-body {
  padding: unset;
}



input[type="checkbox"] {
  width: 35px;
  height: 35px;
  border-radius: 50% !important;
}

.checkbox-group span {
  font-size: 1.2em;
  color: black;
  font-weight: bold;
  text-align: center;
  width: 80%;
  max-width: 80%;
  font-size: 33px;
}

.option-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  padding-bottom: 50px !important;
  justify-content: center;
  align-items: center;
}

.two-images-option-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 50px;
  padding-bottom: 70px !important;
  justify-content: center;
  align-items: center;
}

.option-item {
  padding: 10px;
  border-radius: 5px;
  color: white;
  flex: 1 1 150px;
  text-align: center;
}


.two-images-option-item {
  width: 270px !important;
  height: 270px !important;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.two-images-option-item img {
  width: 180px;
}

.next-icon img {
  width: 45px;
}

#previewQuestions .question-title {
  margin-bottom: 20px;
  text-align: center;
  align-self: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #0D5CAB;
  display: inline-block;
  padding: 20px 25px;
  color: white;
  cursor: pointer;
}

.checkbox-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
  border-radius: 10px;
  padding-top: 13px;
  padding-bottom: 10px;

}

[dir] .modal-footer {
  border-top: 1px solid rgb(247 247 247 / 37%);
}

.circle-checkbox-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  background: #fff;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  min-height: 75px;
  margin-bottom: 23px;
}

.checkbox-col {
  width: 65px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

input[type="checkbox"] {
  flex: 0 0 15%;

}

.checkbox-title {
  flex: 1;
  text-align: center;
  overflow-wrap: break-word;
  text-align: center;
  word-break: break-word;
  padding-left: 65px;
  font-size: 25px;
  height: 100%;
  font-weight: bold;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
